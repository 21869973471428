import { datadogLogs } from '@datadog/browser-logs';
import { DyteAudioVisualizer, DyteAvatar, DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';

import { Loader } from 'components/common';
import { useMeeting } from 'hooks';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';
import { DeviceType, VideoPreviewProps } from 'types';
import CameraInUseError from '../CameraInUseError';
import DeviceOffState from '../DeviceOffState';
import PermissionCheck from '../PermissionCheck';
import { useDeviceType } from 'utils';
import { DEVICE_TYPES } from 'configs/deviceType';

const VideoPreview: FC<VideoPreviewProps> = ({
  cameraInUse,
  permissionsGranted,
  setPermissionsGranted,
  videoVisible,
  videoEnabled,
  userName,
  joinMeeting,
}) => {
  const { meeting, self, studentId, classId, studentName } = useMeeting();
  const { audioEnabled } = meeting.self;
  const { deviceType: tabletDeviceType } = useDeviceType();
  const { checkPermissions, videoPermissionDenied, audioPermissionDenied } =
    useSetupScreenMediaDevices();

  const [deviceType] = useState<DeviceType>(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (/ipad/.test(ua) || (ua.includes('mac') && navigator.maxTouchPoints > 0)) return 'iPadOS';
    if (/iphone|ipod/.test(ua)) return 'iOS';
    if (/android/.test(ua)) return 'Android';
    if (/win/.test(ua)) return 'Windows';
    if (/mac/.test(ua)) return 'macOS';
    return 'Other';
  });

  const handleEnableDevices = useCallback(async () => {
    try {
      if (!videoEnabled && !audioEnabled) {
        await Promise.all([meeting?.self?.enableVideo(), meeting?.self?.enableAudio()]);
      } else if (!videoEnabled) {
        await meeting?.self?.enableVideo();
      } else if (!audioEnabled) {
        await meeting?.self?.enableAudio();
      }
      window.location.reload();
    } catch (error) {
      datadogLogs.logger.error('Error enabling devices:', {
        error:
          error instanceof Error
            ? {
                name: error.name,
                message: error.message,
                stack: error.stack,
              }
            : 'Unknown error',
        studentId,
        classId,
        studentName,
      });
    }
  }, [audioEnabled, classId, meeting?.self, studentId, studentName, videoEnabled]);

  // Effect to handle permission denied states
  useEffect(() => {
    const handlePermissionDenied = async () => {
      // Skip permission checks for mobile and tablet devices
      if (
        tabletDeviceType === DEVICE_TYPES.MOBILE ||
        tabletDeviceType === DEVICE_TYPES.TABLET ||
        deviceType === 'iOS' ||
        deviceType === 'iPadOS' ||
        deviceType === 'Android'
      ) {
        return;
      }
      if (videoPermissionDenied && videoEnabled) {
        await meeting?.self?.disableVideo();
      }
      if (audioPermissionDenied && audioEnabled) {
        await meeting?.self?.disableAudio();
      }
    };

    handlePermissionDenied();
  }, [
    videoPermissionDenied,
    audioPermissionDenied,
    videoEnabled,
    audioEnabled,
    meeting?.self,
    tabletDeviceType,
    deviceType,
  ]);

  // Initial Loading State
  if (!permissionsGranted && !(videoPermissionDenied || audioPermissionDenied)) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className='w-[300px] lg:w-[400px] h-96 font-poppins rounded-xl bg-primary-850 border border-primary-700 p-6'
      >
        <div className='flex flex-col items-center justify-center p-6 space-y-4'>
          <Loader />
        </div>
      </motion.div>
    );
  }

  // Show permission check when permissions are denied or not granted
  if (videoPermissionDenied || audioPermissionDenied) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className='w-[250px] lg:w-[400px] h-96'
      >
        <PermissionCheck
          onPermissionGranted={() => setPermissionsGranted(true)}
          meeting={meeting}
          onCheckSystemPermissions={checkPermissions}
        />
      </motion.div>
    );
  }

  // Show camera in use error
  if (cameraInUse.isInUse) {
    return (
      <CameraInUseError
        deviceType={deviceType}
        onPermissionGranted={() => {
          // Only update permissions if camera becomes available
          if (!cameraInUse.isInUse) {
            setPermissionsGranted(true);
          }
        }}
        joinMeeting={joinMeeting}
      />
    );
  }

  // Show device off state when either video or audio is disabled
  if ((!videoEnabled || !audioEnabled) && !videoPermissionDenied && !audioPermissionDenied) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className='w-[250px] lg:w-[400px] h-96'
      >
        <DeviceOffState
          isVideoOff={!videoEnabled}
          isAudioOff={!audioEnabled}
          onEnable={handleEnableDevices}
        />
      </motion.div>
    );
  }

  // Show video preview
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className='relative w-[250px] lg:w-[400px] h-96 font-poppins'
    >
      <div className='relative h-full border rounded-xl border-[#62667A]'>
        {/* Poor Lighting Warning */}
        {!videoVisible && videoEnabled && (
          <div className='absolute z-10 text-red-500 top-3 left-3'>Poor lighting!</div>
        )}

        {/* Audio Visualizer */}
        <div className='absolute z-10 top-3 right-3'>
          <DyteAudioVisualizer
            size='sm'
            slot='start'
            participant={meeting?.self}
            className={`h-full p-3 text-white ${
              self?.audioEnabled ? 'bg-[#515352]' : 'bg-semantic-red-500'
            } rounded-full`}
          />
        </div>

        {/* Video Tile */}
        <DyteParticipantTile
          size='xl'
          meeting={meeting}
          participant={meeting?.self}
          className='w-full h-96'
        >
          <DyteAvatar size='xl' participant={meeting?.self} />
        </DyteParticipantTile>

        {/* Username */}
        <p className='absolute text-white text-xs px-5 py-1 rounded-es-xl rounded-tr-xl bottom-0 left-0 bg-[#061221]'>
          {userName}
        </p>
      </div>
    </motion.div>
  );
};

export default VideoPreview;
