import { Badge } from 'flowbite-react';
import moment from 'moment';
import { useState } from 'react';
import Countdown from 'react-countdown';

import { useGetCurrentClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { SectionHeadingLogo } from 'assets/svg';
import { Card } from 'components/common';
import { ClassSchedule, TutorProfile } from 'components/screens';
import { USER_TYPE } from 'configs/userData';

import { CLASS_PROGRESS } from 'configs';

export default function StudentUpcomingClass() {
  const [stateOfClass, setStateOfClass] = useState<string>('');
  const [remainingTime, setRemainingTime] = useState<number>(0);
  moment.tz.setDefault('Asia/Kolkata');

  /* API => get current class list */
  const { data: currentClass } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <h1 className='items-center py-10 text-xl font-bold tracking-wider uppercase md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
        <img src={SectionHeadingLogo} alt='uprio section logo' className='my-4 mr-5 md:my-0' />
        Class Details & Summary
      </h1>
      {/* {stateOfClass !== CLASS_PROGRESS.END ? ( */}
      <Card
        className={`w-full flex-1 h-full p-5 rounded-xl my-5 ${stateOfClass === CLASS_PROGRESS.STARTED ? 'bg-neutral-900' : ''}`}
        defaultColorCode='bg-[#D8E8FD]'
      >
        <div className=''>
          <div className='grid lg:grid-cols-4 gap-x-5'>
            <div className=' lg:col-span-1 xl:col-span-1'>
              <h6
                className={`cap-semibold text-lg lg:text-base ${stateOfClass === CLASS_PROGRESS.STARTED ? 'text-primary-400' : 'text-neutral-600'}`}
              >
                TUTOR PROFILE
              </h6>
              <TutorProfile type={USER_TYPE?.STUDENT} state={stateOfClass} />
            </div>
            <div className=' lg:col-span-3 xl:col-span-3'>
              {Object.keys(currentClass?.data || {}).length > 0 ? (
                <div className='flex flex-wrap items-center mt-3 lg:mt-0'>
                  <h6
                    className={`cap-semibold text-lg lg:text-base ${stateOfClass === CLASS_PROGRESS.STARTED ? 'text-primary-400' : 'text-neutral-600'}`}
                  >
                    {stateOfClass === CLASS_PROGRESS.STARTED
                      ? `Ongoing ${currentClass?.data?.batchTypeDetails?.name} class`
                      : stateOfClass === CLASS_PROGRESS.END
                        ? `Past ${currentClass?.data?.batchTypeDetails?.name} class`
                        : `Upcoming ${currentClass?.data?.batchTypeDetails?.name}  Class`}
                  </h6>
                  {stateOfClass === CLASS_PROGRESS.STARTED ? (
                    <Badge className='inline px-2 ml-2 leading-3 text-white rounded-[8px] md:px-4 bg-semantic-red-500'>
                      <span className='text-sm uppercase body-medium'>Live</span>
                    </Badge>
                  ) : stateOfClass === CLASS_PROGRESS.END ? (
                    <Badge className='inline px-2 ml-2 leading-3 text-white rounded-[8px] md:px-4 bg-semantic-orange-500'>
                      <span className='text-sm uppercase body-medium'>Ended</span>
                    </Badge>
                  ) : currentClass?.data ? (
                    <Badge className='inline px-2 ml-2 leading-3 text-white bg-green-500 rounded-[8px] md:px-4'>
                      <span className='text-sm capitalize body-medium'>
                        starting in{' '}
                        {
                          <Countdown
                            date={moment(currentClass?.data?.meetingStartTime).toDate()}
                            renderer={({ days, hours, minutes, seconds }) => {
                              if (days > 0) {
                                return <span>{`${days}d ${hours}h ${minutes}min `}</span>;
                              } else if (hours > 0) {
                                return <span>{`${hours}h ${minutes}min `}</span>;
                              } else if (minutes > 0) {
                                return <span>{`${minutes}min `}</span>;
                              } else {
                                return <span>{`${seconds}s `}</span>;
                              }
                            }}
                          />
                        }
                      </span>
                    </Badge>
                  ) : null}
                </div>
              ) : (
                <div className='flex flex-wrap items-center mt-3 lg:mt-0'>
                  <Badge className='inline px-2 ml-2 leading-3 text-white rounded-[8px] md:px-4 bg-semantic-green-500'>
                    <span className='text-sm uppercase body-medium'>COMPLETED</span>
                  </Badge>
                </div>
              )}

              <ClassSchedule
                state={stateOfClass}
                remainingTime={remainingTime}
                setStateOfClass={setStateOfClass}
                setRemainingTime={setRemainingTime}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
