import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { PreClassScreenProps } from 'types/inClass/PreClassScreen';

import { InClassUprioLogo } from 'assets/svg';

import { Topic } from '../Badges';
import { TimeDisplay } from './TimeDisplay';
import { TutorAvatar } from './TutorAvatar';

export const PreClassScreen = ({
  classStartTime,
  tutorDetails,
  className,
  subject,
}: PreClassScreenProps) => {
  moment.tz.setDefault('Asia/Kolkata');
  const isClassEnded = useMemo(() => moment(classStartTime).isBefore(moment()), [classStartTime]);

  return (
    <div className='flex items-center justify-center min-h-screen px-4 md:px-6'>
      <div className='relative rounded-3xl shadow-lg w-full max-w-[95%] md:max-w-[85%] lg:max-w-[60vw] p-4 md:p-6 bg-primary-850'>
        {/* Header */}
        <div className='flex items-center justify-between mb-8 md:mb-12'>
          <div className='flex items-center justify-between w-full space-x-3 md:space-x-4'>
            <img src={InClassUprioLogo} alt='logo' className='h-6 md:h-8' />
            <Topic
              subject={subject}
              topic={className}
              className='p-1 text-sm text-white rounded-full md:text-base bg-primary-700/50'
            />
          </div>
        </div>

        <div className='flex flex-col gap-2 md:flex-row md:gap-4'>
          {/* Time Section */}
          <div className='w-full p-4 mb-4 md:p-8 md:mb-8 rounded-2xl bg-primary-800/50'>
            <div className='flex flex-col items-center'>
              <TimeDisplay classStartTime={classStartTime} isEnded={isClassEnded} />
            </div>
          </div>

          {/* Tutor Section */}
          {!isEmpty(tutorDetails) && (
            <div className='flex items-center justify-center p-4 mb-4 md:p-8 md:mb-8 rounded-2xl bg-primary-800/50'>
              <div className='flex flex-col items-center space-y-3 md:space-y-4'>
                <TutorAvatar
                  firstName={tutorDetails[0].firstName}
                  lastName={tutorDetails[0].lastName}
                  profileImage={tutorDetails[0].profileImage}
                />
                <div className='text-center'>
                  <p className='text-lg font-medium text-white md:text-xl'>
                    {`${tutorDetails[0].firstName} ${tutorDetails[0].lastName}`}
                  </p>
                  <p className='text-sm text-gray-400 md:text-base'>Tutor</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Footer Status */}
        <div className='mt-2 text-xs text-center md:text-sm text-gray-400/80 font-poppins'>
          {isClassEnded ? 'Class has concluded' : 'Please join at the above time'}
        </div>
      </div>
    </div>
  );
};

export default PreClassScreen;
