import { datadogLogs } from '@datadog/browser-logs';
import { DyteProvider, useDyteClient } from '@dytesdk/react-web-core';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { DyteDialogManager } from '@dytesdk/react-ui-kit';
import { NotificationProvider } from 'components/common/NotificationProvider';
import Meeting from 'components/inClass/Meeting';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import getCurrentState from 'utils/inClass/inClassFunctions';
import { BouncingDotsLoader, SocketProvider } from 'components/common';
import { CONFIG, USER_TYPE } from 'configs';
import { setMyCurrentState } from 'store/slice/inClassConfig.slice';
import { useAppDispatch } from 'hooks/store';
import PreClassScreen from 'components/inClass/PreClassScreen';
import { PreClassErrorScreen } from 'components/inClass/PreClassErrorScreen';
import ZohoSalesIQ from 'components/common/ChatBot/ZohoSalesIQ';

// Get browser-specific media constraints
const getBrowserMediaConstraints = (browserName: string) => {
  const isFirefox = browserName.includes('firefox');
  const isSafari = browserName.includes('safari') && !browserName.includes('chrome');

  const baseConstraints = {
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
    video: {
      width: { ideal: 1280, max: 1280 },
      height: { ideal: 720, max: 720 },
    },
  };

  if (isFirefox) {
    return {
      ...baseConstraints,
      video: {
        ...baseConstraints.video,
        frameRate: { ideal: 30 },
      },
    };
  }

  if (isSafari) {
    return {
      audio: {
        ...baseConstraints.audio,
        sampleRate: { ideal: 48000 },
        channelCount: { ideal: 2 },
      },
      video: {
        ...baseConstraints.video,
        frameRate: { max: 30 },
      },
    };
  }

  return baseConstraints;
};

export default function StudentClassroom() {
  const dispatch = useAppDispatch();
  const [meetingToken, setMeetingToken] = useState<string>('');
  const [flag, setFlag] = useState<boolean>(false);
  // const { classId, studentId } = useMeeting();
  const { id } = useParams();
  const authToken = String(id);
  const [classId, setClassId] = useState<string>('');
  // API Call -> Get the meeting token
  const {
    data: joinClassRoomData,
    isSuccess,
    error,
    isFetching,
  } = useGetClassJoinTokenQuery(
    {
      authToken,
    },
    {
      skip: !authToken,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      setClassId(String(joinClassRoomData?.data?.classId));
    }
  }, [isSuccess, joinClassRoomData?.data]);

  // Initialize the meeting
  const [meeting, initMeeting] = useDyteClient();

  // Enhanced getMedia function with browser-specific handling
  const getMedia = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      datadogLogs.logger.error(
        `Media devices API not supported for student with studentId:${joinClassRoomData?.data?.studentId}`,
        {
          classId: joinClassRoomData?.data?.classId,
          studentId: joinClassRoomData?.data?.studentId,
        },
      );
      setFlag(false);
      return;
    }

    const browserName = navigator.userAgent.toLowerCase();
    const constraints = getBrowserMediaConstraints(browserName);

    try {
      // First attempt with optimal settings
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());

      datadogLogs.logger.info(
        `Media permissions granted for student with studentId:${joinClassRoomData?.data?.studentId}`,
        {
          classId: joinClassRoomData?.data?.classId,
          studentId: joinClassRoomData?.data?.studentId,
          browser: browserName,
        },
      );
    } catch (initialError) {
      // For Firefox and Safari, try fallback approach
      try {
        // Try audio only first
        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: constraints.audio,
          video: false,
        });
        audioStream.getTracks().forEach((track) => track.stop());

        // Then try video with reduced quality
        const videoStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: { ideal: 640, max: 1280 },
            height: { ideal: 480, max: 720 },
          },
        });
        videoStream.getTracks().forEach((track) => track.stop());

        datadogLogs.logger.info(`Media permissions granted with fallback for student`, {
          classId: joinClassRoomData?.data?.classId,
          studentId: joinClassRoomData?.data?.studentId,
          browser: browserName,
        });
      } catch (fallbackError) {
        datadogLogs.logger.error(`Failed to access media devices for student`, {
          error: fallbackError,
          classId: joinClassRoomData?.data?.classId,
          studentId: joinClassRoomData?.data?.studentId,
          browser: browserName,
        });
      }
    } finally {
      setTimeout(() => {
        setFlag(false);
      }, 2000);
    }
  }, [joinClassRoomData?.data?.classId, joinClassRoomData?.data?.studentId]);

  // Media initialization effect
  useEffect(() => {
    if (joinClassRoomData?.data?.studentId) {
      datadogRum.setUser({
        id: joinClassRoomData.data.studentId.toString(),
        userType: USER_TYPE.STUDENT,
      });
    }

    if (
      joinClassRoomData?.data?.classId &&
      joinClassRoomData?.data?.studentId &&
      joinClassRoomData?.data?.meetingToken
    ) {
      setFlag(true);
      getMedia();
    }
  }, [
    joinClassRoomData?.data?.classId,
    joinClassRoomData?.data?.studentId,
    joinClassRoomData?.data?.meetingToken,
    getMedia,
  ]);

  // When the query is successful, set the meeting token
  useEffect(() => {
    if (isSuccess && joinClassRoomData) {
      setMeetingToken(joinClassRoomData?.data?.meetingToken);
    }
  }, [isSuccess, joinClassRoomData]);

  // When the meeting token changes, initialize the meeting
  useEffect(() => {
    if (meetingToken) {
      // TODO: Here Have added the auto switching for the audio devices if not working means have to remove this from the meeting initializing
      initMeeting({
        authToken: meetingToken,
        defaults: {
          audio: true,
          video: true,
          autoSwitchAudioDevice: true,
        },
      })
        .then(async (meet) => {
          if (meet && meet?.self?.customParticipantId) {
            const currentStatus = await getCurrentState(String(classId));
            const selectedStudentStatus = currentStatus?.data.find(
              (data: { studentId: number | undefined }) =>
                data.studentId === joinClassRoomData?.data?.studentId,
            );
            if (selectedStudentStatus?.currentState) {
              dispatch(setMyCurrentState(selectedStudentStatus?.currentState));
            }
            datadogLogs.logger.info(
              `Socket connected for student with studentId:${joinClassRoomData?.data?.studentId} and classId:${joinClassRoomData?.data?.classId}`,
              {
                classId: joinClassRoomData?.data?.classId,
                studentId: joinClassRoomData?.data?.studentId,
                browser: navigator.userAgent.toLowerCase(),
              },
            );
          }
        })
        .catch((error) => {
          datadogLogs.logger.error('Error initializing the meeting', {
            error,
            browser: navigator.userAgent.toLowerCase(),
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingToken]);

  if (flag || isFetching) {
    return (
      <div className='flex items-center justify-center h-[100vh]'>
        <BouncingDotsLoader />
      </div>
    );
  }

  return isSuccess ? (
    joinClassRoomData?.data?.inOngoingClass ? (
      <>
        <DyteProvider value={meeting}>
          <SocketProvider
            userId={joinClassRoomData?.data?.studentId?.toString()}
            userType='student'
            classId={joinClassRoomData?.data?.classId?.toString()}
          >
            <NotificationProvider>
              <DyteDialogManager meeting={meeting} />
              <Meeting />
            </NotificationProvider>
          </SocketProvider>
        </DyteProvider>
        {CONFIG.VITE_BASE_DOMAIN_ENV !== 'production' && <ZohoSalesIQ />}
      </>
    ) : (
      <PreClassScreen
        classStartTime={joinClassRoomData?.data?.meetingStartTime}
        tutorDetails={joinClassRoomData?.data?.tutorDetails}
        subject={joinClassRoomData?.data?.subject}
        className={
          joinClassRoomData?.data?.lectureConfigurationName || joinClassRoomData?.data?.name
        }
      />
    )
  ) : (
    <PreClassErrorScreen error={error} />
  );
}
