import { map } from 'lodash';

export const CONFIG = {
  API_URL: import.meta.env.VITE_BASE_URL,
  SOCKET_URL: import.meta.env.VITE_BASE_SOCKET_URL,
  ROOMS: {
    TEACH: 'teach',
    COACH: 'coach',
    COACH_BASIC: 'coach_basic',
    COACH_INTERMEDIATE: 'coach_intermediate',
    COACH_ADVANCE: 'coach_advance',
  },
  MIN_NETWROK_SCORE: import.meta.env.VITE_BASE_MIN_NETWORK_SCORE,
  SEND_NETWORK_ALERT_MIN_COUNTER: import.meta.env.VITE_BASE_MIN_NETWORK_ALERT_COUNTER,
  DEFAULT_ATTENTION: [
    75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75, 75,
    75, 75, 75, 75, 75, 75, 75, 75,
  ],
  RECORDING_DISABLED_FOR_STUDENTS: import.meta.env.VITE_BASE_RECORDING_DISABLED_FOR_STUDENTS || '',
  VITE_BASE_DOMAIN_ENV: import.meta.env.VITE_BASE_DOMAIN_ENV,
  IS_DEFAULT_AUDIO_COMPONENT: import.meta.env.VITE_IS_DEFAULT_AUDIO_COMPONENT || 'false',
  REPORTS_ENABLED: import.meta.env.VITE_REPORTS_ENABLED === 'true',
  REPORT_ENABLED_STUDENTS: map(
    (import.meta.env.VITE_REPORT_ENABLED_STUDENTS || '').split(','),
    Number,
  ),
};

export const MORPHCAST = {
  API_KEY: import.meta.env.VITE_BASE_LICENSE_KEY,
  BACKEND: import.meta.env.VITE_BASE_SOCKET_URL,
  ATTENTION_INTERVAL: import.meta.env.VITE_BASE_ATTENTION_CAPTURE_INTERVAL,
  INITIAL_WAIT: import.meta.env.VITE_BASE_ATTENTION_INITIAL_WAIT,
  MAX_INPUT_FRAME_SIZE: import.meta.env.VITE_BASE_MORPH_MAX_INPUT_FACE_SIZE,
};

export const DOCUSHARE_PLUGIN_ID = import.meta.env.VITE_BASE_DOCUSHARE_PLUGIN_ID;
export const WHITEBOARD_PLUGIN_ID = import.meta.env.VITE_BASE_WHITEBOARD_PLUGIN_ID;

export const PARENT_DASHBOARD_ENABLE_AUDIO = import.meta.env.VITE_BASE_PD_ENABLE_AUDIO;

export const HOST_PRESET_ID = import.meta.env.VITE_BASE_HOST_PRESET_ID;

export const DOMAIN_URL = import.meta.env.VITE_BASE_DOMAIN_URL;

export const DOMAIN_ENV = import.meta.env.VITE_BASE_DOMAIN_ENV;

export const SHOW_QRCODE_PRACTICE_QUESTIONS = import.meta.env
  .VITE_BASE_SHOW_QRCODE_PRACTICE_EVALUATION;

export const SANITY_PROJECT_ID = import.meta.env.VITE_SANITY_PROJECT_ID;

export const SANITY_DATASET = import.meta.env.VITE_SANITY_DATASET;

export const GUIDED_SOLUTIONS = {
  MODE: import.meta.env.VITE_GUIDED_SOLUTIONS_MODE === 'manual',
  TIMEOUT: import.meta.env.VITE_GUIDED_SOLUTIONS_TIMEOUT_MS,
};

export const VITE_ZOHO_SRC_URL = import.meta.env.VITE_ZOHO_SRC_URL;

export const ENABLE_OLD_SETUP_SCREEN = import.meta.env.VITE_ENABLE_OLD_SETUP_SCREEN;

export const CONTACT_SUPPORT_NUMBER = import.meta.env.VITE_CONTACT_SUPPORT_NUMBER;
