import { useGetCurrentClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { BouncingDotsLoader, Footer } from 'components/common';
import {
  DashboardNav,
  FutureClassList,
  LearningMaterials,
  StudentUpcomingClass,
} from 'components/screens';
import { USER_TYPE } from 'configs/userData';
import { ICurrentClassDetails } from 'types/democlass';
import ZohoSalesIQ from 'components/common/ChatBot/ZohoSalesIQ';
import { CONFIG } from 'configs';

export default function StudentDashboard() {
  /* API => get current class list */
  const { data: currentClassResponse, isLoading } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <div className='flex flex-col flex-grow min-h-screen bg-neutral-25'>
        <DashboardNav
          type={USER_TYPE.STUDENT}
          batchType={currentClassResponse?.data?.batchTypeDetails?.name}
        />
        {isLoading ? (
          <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        ) : (
          <div className='flex-1 h-full mx-2 xl:mx-6'>
            <StudentUpcomingClass />
            <FutureClassList />
            <LearningMaterials
              learningMaterial={currentClassResponse?.data as ICurrentClassDetails}
            />
          </div>
        )}
        <Footer />
        {CONFIG.VITE_BASE_DOMAIN_ENV !== 'production' && <ZohoSalesIQ />}
      </div>
    </>
  );
}
