import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useNavigate } from 'react-router-dom';

import { ExclamationCircle, InClassUprioLogo } from 'assets/svg';

import { CONTACT_SUPPORT_NUMBER, STUDENT_PATHS } from 'configs';
import InClassButton from '../Button';

interface PreClassErrorScreenProps {
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const PreClassErrorScreen = ({ error }: PreClassErrorScreenProps) => {
  const errorStatus = (error as FetchBaseQueryError)?.status;
  const navigate = useNavigate();

  const getErrorMessage = () => {
    switch (errorStatus) {
      case 404:
        return 'Looks like you have the wrong link!';
      case 403:
        return "You don't have permission to access this resource";
      case 500:
        return 'Server error occurred. Please try again later';
      default:
        return 'Something went wrong!';
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen px-4 md:px-6'>
      <div className='relative w-full max-w-[95%] md:max-w-[85%] lg:max-w-[60vw] rounded-3xl bg-primary-850 backdrop-blur-lg p-6 md:p-8 shadow-xl border border-white/10'>
        {/* Header */}
        <div className='flex items-center justify-between mb-8 md:mb-12'>
          <div className='flex items-center justify-between w-full'>
            <img src={InClassUprioLogo} alt='logo' className='h-6 md:h-8' />
          </div>
        </div>

        {/* Error Content */}
        <div className='flex flex-col items-center mb-8 space-y-6 text-center'>
          <img src={ExclamationCircle} className='h-10 md:h-12' alt='alert' />

          <div className='flex flex-col items-center space-y-2'>
            <h1 className='text-2xl font-semibold text-white md:text-3xl font-sora'>
              {getErrorMessage()}
            </h1>
            <p className='items-center max-w-lg text-base text-gray-400 md:text-lg font-poppins'>
              We're sorry for the inconvenience. Please contact your School Account Manager for
              assistance.
            </p>
          </div>

          <div className='flex flex-col justify-center w-full max-w-md gap-4 sm:flex-row'>
            <InClassButton
              color='green'
              title='Go Home'
              rounded={false}
              onClick={() => {
                navigate(STUDENT_PATHS.STUDENT_DASHBOARD);
              }}
            />
          </div>
        </div>

        {/* Footer */}
        <div className='flex items-center justify-center gap-2 text-sm text-gray-400 font-poppins'>
          <span>Need help?</span>
          <a
            href={`tel:${CONTACT_SUPPORT_NUMBER}`}
            className='transition-colors duration-200 text-primary-400 hover:text-primary-300'
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default PreClassErrorScreen;
