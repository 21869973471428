/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { datadogLogs } from '@datadog/browser-logs';
import { DEVICE_TYPES } from 'configs/deviceType';
import { SocketContext } from 'contexts';
import { useMeeting } from 'hooks';
import { debounce, isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IBrowserCompatibility, ICameraInUseState, IDeviceState } from 'types';
import { getBrowserInfo, isTabletDevice, useDeviceType } from 'utils';

// New interface for permission check state
interface IPermissionCheckState {
  checkCount: number;
  lastCheckTime: number;
  shouldCheck: boolean;
}

export const useSetupScreenMediaDevices = () => {
  const { meeting, studentId, classId, studentName } = useMeeting();
  const socket = useContext(SocketContext);
  const { deviceType } = useDeviceType();
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [speakerDevices, setSpeakerDevices] = useState<MediaDeviceInfo[]>([]);
  const [currentSelectedDevice, setCurrentSelectedDevice] = useState<IDeviceState>({
    audio: 'default',
    video: 'default',
    speaker: 'default',
  });

  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [audioPermissionDenied, setAudioPermissionDenied] = useState<boolean>(false);
  const [browserCompatibility, setBrowserCompatibility] = useState<IBrowserCompatibility>({
    isCompatible: true,
  });
  const [cameraInUse, setCameraInUse] = useState<ICameraInUseState>({ isInUse: false });

  // Cache for active streams
  const streamsCache = useRef(new Map());
  const mountedRef = useRef(true);
  const isCheckingRef = useRef(false);

  // New ref for tracking permission check state
  const permissionCheckStateRef = useRef<{
    video: IPermissionCheckState;
    audio: IPermissionCheckState;
  }>({
    video: { checkCount: 0, lastCheckTime: 0, shouldCheck: true },
    audio: { checkCount: 0, lastCheckTime: 0, shouldCheck: true },
  });

  // Add ref to track previous states
  const prevStateRef = useRef<{
    video: boolean;
    audio: boolean;
    devices: {
      audioDevices: string[];
      videoDevices: string[];
      speakerDevices: string[];
      currentSelectedDevice: IDeviceState;
    } | null;
  }>({
    video: false,
    audio: false,
    devices: null,
  });

  const resetPermissionCheckState = (type: 'video' | 'audio') => {
    permissionCheckStateRef.current[type] = {
      checkCount: 0,
      lastCheckTime: 0,
      shouldCheck: true,
    };
  };

  // Check browser compatibility
  useEffect(() => {
    const checkBrowserCompatibility = () => {
      const browserInfo = getBrowserInfo();
      const hasRequiredAPIs = !!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices);

      if (!hasRequiredAPIs) {
        setBrowserCompatibility({
          isCompatible: false,
          reason: 'Browser does not support required media APIs',
        });
        return;
      }

      if (browserInfo.name === 'chrome') {
        const version = parseInt(browserInfo.version);
        if (version < 60) {
          setBrowserCompatibility({
            isCompatible: false,
            reason: 'Chrome version 60 or higher is required',
          });
        }
      } else if (browserInfo.name === 'firefox') {
        const version = parseInt(browserInfo.version);
        if (version < 52) {
          setBrowserCompatibility({
            isCompatible: false,
            reason: 'Firefox version 52 or higher is required',
          });
        }
      } else if (browserInfo.name === 'safari') {
        const version = parseInt(browserInfo.version);
        if (version < 11) {
          setBrowserCompatibility({
            isCompatible: false,
            reason: 'Safari version 11 or higher is required',
          });
        }
      }
    };

    checkBrowserCompatibility();
  }, []);

  const emitDeviceUpdate = useCallback(
    debounce((deviceInfo: any) => {
      // Only emit if socket exists and is connected
      if (socket === null || !socket) {
        return;
      }

      // Strict state comparison
      const currentState = {
        video: !videoPermissionDenied,
        audio: !audioPermissionDenied,
        devices: {
          audioDevices: audioDevices.map((d) => d.deviceId),
          videoDevices: videoDevices.map((d) => d.deviceId),
          speakerDevices: speakerDevices.map((d) => d.deviceId),
          currentSelectedDevice,
        },
      };

      // Deep comparison of previous and current states
      const hasStateChanged =
        prevStateRef.current.video !== currentState.video ||
        prevStateRef.current.audio !== currentState.audio ||
        !isEqual(prevStateRef.current.devices, currentState.devices);

      if (hasStateChanged && socket?.connected) {
        socket.emit('studentDevices', {
          studentId,
          devicesInfo: { ...deviceInfo },
        });

        // Update previous state after successful emission
        prevStateRef.current = currentState;
      }
    }, 2000),
    [
      studentId,
      classId,
      videoPermissionDenied,
      audioPermissionDenied,
      audioDevices,
      videoDevices,
      speakerDevices,
      currentSelectedDevice,
    ],
  );

  const checkCameraInUse = useCallback(async (deviceId?: string) => {
    try {
      if (!deviceId) {
        return { isInUse: false };
      }

      const constraints = {
        video: deviceId
          ? {
              deviceId: { exact: deviceId },
              width: { ideal: 640 }, // Add compatible constraints for Edge
              height: { ideal: 480 },
            }
          : true,
      };

      await navigator.mediaDevices.getUserMedia(constraints);
      return { isInUse: false };
    } catch (error: any) {
      const isInUse =
        error.name === 'NotReadableError' ||
        error.message.includes('Could not start video source') ||
        error.message.includes('Starting video failed');

      if (isInUse) {
        datadogLogs.logger.info('Camera in use detected', {
          deviceId,
          studentId,
          classId,
          studentName,
          error: error.name,
          message: error.message,
          browserInfo: getBrowserInfo(),
        });
      }

      return { isInUse, deviceId };
    }
  }, []);

  // Optimized fetchDevices with caching
  const fetchDevices = useCallback(async () => {
    if (!browserCompatibility?.isCompatible || !mountedRef.current) return;

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const isTablet = isTabletDevice();

      // Process audio devices
      const audioInputs = devices?.filter((device) => device?.kind === 'audioinput');
      if (audioInputs?.length > 0) {
        setAudioDevices(audioInputs);
      }

      // Process video devices with optimized camera-in-use check
      const videoInputs = devices?.filter((device) => device?.kind === 'videoinput');
      if (videoInputs?.length > 0) {
        setVideoDevices(videoInputs);

        // Only check camera in use if video is enabled
        if (meeting?.self?.videoEnabled) {
          for (const device of videoInputs) {
            if (!streamsCache.current.has(device.deviceId)) {
              const { isInUse, deviceId } = await checkCameraInUse(device.deviceId);
              if (isInUse) {
                setCameraInUse({ isInUse: true, deviceId });
                break;
              }
            }
          }
        }
      }

      // Process speaker devices with iPad check
      if (isTablet) {
        // For iPads, set a default speaker
        setSpeakerDevices([
          {
            deviceId: 'default',
            kind: 'audiooutput',
            label: 'iPad Speaker',
            groupId: '',
            toJSON: () => ({
              deviceId: 'default',
              kind: 'audiooutput',
              label: 'iPad Speaker',
              groupId: '',
            }),
          } as MediaDeviceInfo,
        ]);
      } else {
        const audioOutputs = devices?.filter((device) => device?.kind === 'audiooutput');
        if (audioOutputs?.length > 0) {
          setSpeakerDevices(audioOutputs);
        }
      }

      // Update current selected devices if needed
      const currentDevices = meeting?.self?.getCurrentDevices() ?? {};
      setCurrentSelectedDevice((prev) => ({
        audio: currentDevices?.audio?.deviceId || prev.audio,
        video: currentDevices?.video?.deviceId || prev.video,
        speaker: isTablet
          ? 'default'
          : currentDevices?.speaker?.deviceId || prev.speaker || 'default',
      }));

      // Emit device update
      emitDeviceUpdate({
        audioDevices: audioInputs,
        videoDevices: videoInputs,
        speakerDevices: isTablet ? [] : devices?.filter((device) => device?.kind === 'audiooutput'),
        currentSelectedDevice,
      });
    } catch (error) {
      datadogLogs.logger.error(`Error fetching devices`, {
        error,
        studentId,
        classId,
        browserInfo: getBrowserInfo(),
      });
    }
  }, [
    browserCompatibility?.isCompatible,
    meeting?.self,
    currentSelectedDevice,
    studentId,
    classId,
  ]);

  const checkBrowserPermission = useCallback(async (deviceType: 'video' | 'audio') => {
    const permissionName = deviceType === 'video' ? 'camera' : 'microphone';
    try {
      // First check if device is already enabled in meeting
      if (deviceType === 'video' && meeting?.self?.videoEnabled) {
        setVideoPermissionDenied(false);
        return true;
      }
      if (deviceType === 'audio' && meeting?.self?.audioEnabled) {
        setAudioPermissionDenied(false);
        return true;
      }
      const result = await navigator.permissions.query({ name: permissionName as PermissionName });
      await fetchDevices();
      return result.state !== 'denied';
    } catch {
      return true; // Fallback if permissions API not supported
    }
  }, []);

  const checkSystemPermission = useCallback(
    async (deviceType: 'video' | 'audio') => {
      // First check if device is already enabled in meeting
      if (deviceType === 'video' && meeting?.self?.videoEnabled) {
        setVideoPermissionDenied(false);
        return true;
      }
      if (deviceType === 'audio' && meeting?.self?.audioEnabled) {
        setAudioPermissionDenied(false);
        return true;
      }
      try {
        const constraints = {
          [deviceType]:
            deviceType === 'video'
              ? { width: { ideal: 640 }, height: { ideal: 480 } } // More compatible video constraints
              : true, // Simplified audio constraints for Edge
        };

        // Set timeout for faster failure
        const permissionPromise = navigator.mediaDevices.getUserMedia(constraints);

        const stream = (await Promise.race([permissionPromise])) as MediaStream;

        // Verify stream is valid
        if (stream?.active && stream.getTracks().length > 0) {
          if (deviceType === 'video') {
            setVideoPermissionDenied(false);
          } else {
            setAudioPermissionDenied(false);
          }
          await fetchDevices();
          return true;
        }

        datadogLogs.logger.warn(`Invalid stream for ${deviceType}`, {
          studentId,
          classId,
          deviceType,
          constraints,
          browserInfo: getBrowserInfo(),
          stream,
          studentName,
        });
        return false;
      } catch (error: any) {
        // Handle camera in use error
        if (
          error?.name === 'NotReadableError' ||
          error?.message?.toLowerCase()?.includes('camera is in use')
        ) {
          if (deviceType === 'video') {
            // Update camera in use state
            setCameraInUse({
              isInUse: true,
              deviceId: currentSelectedDevice.video,
            });
            // Don't set permission denied since it's just in use
            setVideoPermissionDenied(false);
            datadogLogs.logger.info('Camera is in use', {
              studentId,
              classId,
              deviceId: currentSelectedDevice.video,
              browserInfo: getBrowserInfo(),
            });
            return true; // Return true since it's not a permission issue
          }
        }

        // Handle permission denied cases
        const isPermissionDenied =
          error.name === 'NotAllowedError' ||
          error.name === 'PermissionDeniedError' || // Edge specific
          error.name === 'NotFoundError' ||
          error.message.includes('Permission denied') ||
          error.message.includes('Permission request timeout');

        if (isPermissionDenied) {
          if (deviceType === 'video') {
            setVideoPermissionDenied(true);
            // Reset camera in use state when permission is denied
            setCameraInUse({ isInUse: false, deviceId: undefined });
          } else {
            setAudioPermissionDenied(true);
          }
          return false;
        }

        // For other errors, don't change permission state
        datadogLogs.logger.error(`Permission check error for ${deviceType}:`, {
          error,
          studentId,
          classId,
          deviceType,
          studentName,
          browserInfo: getBrowserInfo(),
        });
        return true;
      }
    },
    [meeting?.self?.videoEnabled, meeting?.self?.audioEnabled, currentSelectedDevice.video],
  );

  const checkPermissions = useCallback(async () => {
    // Skip permission checks for mobile and tablet devices
    if (deviceType === DEVICE_TYPES.MOBILE || deviceType === DEVICE_TYPES.TABLET) {
      return;
    }

    if (isCheckingRef.current || !mountedRef.current || !browserCompatibility?.isCompatible) {
      return;
    }

    isCheckingRef.current = true;

    try {
      // First check browser permissions
      const videoBrowserAllowed = await checkBrowserPermission('video');
      const audioBrowserAllowed = await checkBrowserPermission('audio');

      // Only check system permissions if browser permissions are granted
      if (videoBrowserAllowed) {
        await checkSystemPermission('video');
      } else {
        setVideoPermissionDenied(true);
      }

      if (audioBrowserAllowed) {
        await checkSystemPermission('audio');
      } else {
        setAudioPermissionDenied(true);
      }
    } catch (error) {
      datadogLogs.logger.error('Permission check failed', {
        studentId,
        studentName,
        classId,
        error: error instanceof Error ? error : 'Unknown error',
      });
    } finally {
      isCheckingRef.current = false;
    }
  }, [browserCompatibility?.isCompatible, studentName, studentId, classId]);

  // Separate polling for camera and microphone
  useEffect(() => {
    // Skip permission checks for mobile and tablet devices
    if (deviceType === DEVICE_TYPES.MOBILE || deviceType === DEVICE_TYPES.TABLET) {
      return;
    }

    if (!mountedRef.current || !browserCompatibility?.isCompatible) return;

    // Camera checks more frequently (faster response)
    const cameraInterval = setInterval(async () => {
      const videoBrowserAllowed = await checkBrowserPermission('video');
      if (videoBrowserAllowed) {
        await checkSystemPermission('video');
      } else {
        setVideoPermissionDenied(true);
      }
    }, 300); // 300ms for camera

    // Microphone checks with optimized constraints
    const micInterval = setInterval(async () => {
      const audioBrowserAllowed = await checkBrowserPermission('audio');
      if (audioBrowserAllowed) {
        await checkSystemPermission('audio');
      } else {
        setAudioPermissionDenied(true);
      }
    }, 500); // 500ms for microphone

    return () => {
      clearInterval(cameraInterval);
      clearInterval(micInterval);
    };
  }, [checkBrowserPermission, checkSystemPermission, browserCompatibility?.isCompatible]);

  const handleMediaSelect = useCallback(
    async (device: MediaDeviceInfo) => {
      if (!browserCompatibility?.isCompatible || !mountedRef.current) return;

      try {
        let stream = streamsCache.current.get(device.deviceId);

        if (!stream) {
          stream = await navigator.mediaDevices.getUserMedia({
            [device.kind.replace('input', '')]: {
              deviceId: { exact: device.deviceId },
            },
          });
          streamsCache.current.set(device.deviceId, stream);
        }

        await meeting.self?.setDevice(device);

        // Update current selected device
        setCurrentSelectedDevice((prev) => {
          const newState = { ...prev };
          if (device.kind === 'audioinput') {
            newState.audio = device.deviceId;
          } else if (device.kind === 'videoinput') {
            newState.video = device.deviceId;
          } else if (device.kind === 'audiooutput') {
            newState.speaker = device.deviceId;
          }
          return newState;
        });

        // Emit device update
        emitDeviceUpdate({
          audioDevices,
          videoDevices,
          speakerDevices,
          currentSelectedDevice,
        });
      } catch (error) {
        datadogLogs.logger.error(`Error setting device for ${studentId} and classId:${classId}`, {
          error,
          deviceId: device.deviceId,
          deviceKind: device.kind,
          studentId,
          classId,
        });
      }
    },
    [
      browserCompatibility?.isCompatible,
      meeting.self,
      audioDevices,
      videoDevices,
      speakerDevices,
      currentSelectedDevice,
      studentId,
      classId,
    ],
  );

  // Setup permission monitoring
  useEffect(() => {
    // Skip permission checks for mobile and tablet devices
    if (deviceType === DEVICE_TYPES.MOBILE || deviceType === DEVICE_TYPES.TABLET) {
      return;
    }

    mountedRef.current = true;

    if (!browserCompatibility.isCompatible) return;

    // Set up continuous permission monitoring
    const setupPermissionMonitoring = async () => {
      // Initial check
      await checkPermissions();
      await fetchDevices();
      // Set up permission polling with longer interval
      const permissionPoll = setInterval(() => {
        if (mountedRef.current) {
          checkPermissions();
        }
      }, 5000);
      return () => {
        clearInterval(permissionPoll);
      };
    };

    // Handle device changes with debounce
    const handleDeviceChange = debounce(async () => {
      if (mountedRef.current) {
        resetPermissionCheckState('video');
        resetPermissionCheckState('audio');
        // Check permissions only if devices are disabled
        if (!meeting?.self?.videoEnabled || !meeting?.self?.audioEnabled) {
          await checkPermissions();
        }
      }
    }, 1000);

    let cleanupPermissionPoll: (() => void) | undefined;
    setupPermissionMonitoring().then((cleanup) => {
      cleanupPermissionPoll = cleanup;
    });
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    return () => {
      mountedRef.current = false;

      if (cleanupPermissionPoll) {
        cleanupPermissionPoll();
      }
      if (handleDeviceChange.cancel) {
        handleDeviceChange.cancel();
      }

      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);

      // Cleanup streams
      streamsCache.current.forEach((stream: MediaStream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      streamsCache.current.clear();
    };
  }, [browserCompatibility.isCompatible, meeting?.self?.videoEnabled, meeting?.self?.audioEnabled]);

  return {
    audioDevices,
    videoDevices,
    speakerDevices,
    currentSelectedDevice,
    fetchDevices,
    checkPermissions,
    videoPermissionDenied,
    audioPermissionDenied,
    browserCompatibility,
    cameraInUse,
    handleMediaSelect,
  };
};
