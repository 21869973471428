import { useEffect, useMemo } from 'react';
import { compact, join } from 'lodash';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { VITE_ZOHO_SRC_URL } from 'configs';
import { useLocation } from 'react-router-dom';

interface MediaDevice {
  cameras: string[];
  microphones: string[];
  speakers: string[];
  activeCamera: string | null;
  activeMicrophone: string | null;
  activeSpeaker: string | null;
}

const getFullName = (fName?: string, lName?: string): string => {
  return join(compact([fName, lName]), ' ');
};

const getMediaDevices = (): Promise<MediaDevice> => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.error('Media Devices API not supported in this browser');
    return Promise.reject(new Error('Media Devices API not supported'));
  }

  const updateDevices = (): Promise<MediaDevice> => {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras: string[] = [];
        const microphones: string[] = [];
        const speakers: string[] = [];

        let activeCamera = 'No Active Camera Source';
        let activeMicrophone = 'No Active Mic Source';
        let activeSpeaker = 'No Active Speaker Source';

        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            cameras.push(device.label);
            if (device.deviceId === 'default') {
              activeCamera = device.label;
            }
          } else if (device.kind === 'audioinput') {
            microphones.push(device.label);
            if (device.deviceId === 'default') {
              activeMicrophone = device.label;
            }
          } else if (device.kind === 'audiooutput') {
            speakers.push(device.label);
            if (device.deviceId === 'default') {
              activeSpeaker = device.label;
            }
          }
        });

        if (activeCamera === 'No Active Camera Source' && cameras.length > 0) {
          activeCamera = cameras[0];
        }
        if (activeMicrophone === 'No Active Mic Source' && microphones.length > 0) {
          activeMicrophone = microphones[0];
        }
        if (activeSpeaker === 'No Active Speaker Source' && speakers.length > 0) {
          activeSpeaker = speakers[0];
        }

        return {
          cameras,
          microphones,
          speakers,
          activeCamera: activeCamera,
          activeMicrophone: activeMicrophone,
          activeSpeaker: activeSpeaker,
        };
      })
      .catch((error) => {
        console.error('Error accessing media devices:', error);
        return {
          cameras: [],
          microphones: [],
          speakers: [],
          activeCamera: 'No Active Camera Source',
          activeMicrophone: 'No Active Mic Source',
          activeSpeaker: 'No Active Speaker Source',
        };
      });
  };

  return updateDevices();
};

const ZohoSalesIQ = () => {
  const location = useLocation();
  const isClassJoinRoute = location.pathname.includes('class/join');

  const { data: profileData } = useGetUserDataQuery();
  const userData = useMemo(() => {
    return {
      role: 'Student',
      name: profileData
        ? getFullName(profileData?.data?.firstName, profileData?.data?.lastName)
        : '',
      email: profileData?.data?.email || '',
      contactNumber: profileData?.data?.mobileNumber || '',
    };
  }, [profileData]);

  useEffect(() => {
    const { name, email, contactNumber } = userData;
    const fetchDevices = async () => {
      const updateZohoScript = (sources: MediaDevice) => {
        let script = '';

        if (!isClassJoinRoute) {
          script = `window.$zoho = window.$zoho || {};
                    $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
                    $zoho.salesiq.visitor.name("${name}");
                    $zoho.salesiq.visitor.email("${email}");
                    $zoho.salesiq.visitor.contactnumber("${contactNumber.toString()}");
                    $zoho.salesiq.visitor.info({
                        "role" : "Student",
                        "landingScreen": "admin",
                    });`;
        } else {
          script = `window.$zoho = window.$zoho || {};
                    $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
                    $zoho.salesiq.visitor.name("${name}");
                    $zoho.salesiq.visitor.email("${email}");
                    $zoho.salesiq.visitor.contactnumber("${contactNumber.toString()}");
                    $zoho.salesiq.visitor.info({
                    "role" : "Student", 
                    "landingScreen": "inclass",
                    "activeCamera": "${sources.activeCamera}", 
                    "activeSpeaker": "${sources.activeSpeaker}",
                    "activeMicrophone": "${sources.activeMicrophone}"
                });`;
        }

        const initScript = document.createElement('script');
        initScript.text = script;
        document.body.appendChild(initScript);
        return initScript;
      };

      let initScript = updateZohoScript(await getMediaDevices());

      const widgetScript = document.createElement('script');
      widgetScript.id = 'zsiqscript';
      widgetScript.src = VITE_ZOHO_SRC_URL;
      widgetScript.defer = true;
      document.body.appendChild(widgetScript);

      navigator.mediaDevices.ondevicechange = async () => {
        const updatedDevices = await getMediaDevices();
        document.body.removeChild(initScript);
        initScript = updateZohoScript(updatedDevices);
      };

      return () => {
        document.body.removeChild(initScript);
        document.body.removeChild(widgetScript);
      };
    };

    fetchDevices();
  }, [isClassJoinRoute, profileData, userData]);

  return null;
};

export default ZohoSalesIQ;
