import React from 'react';
import { Footer } from 'components/common';
import { CONFIG, USER_TYPE } from 'configs';
import { ClassNotesView, DashboardNav } from 'components/screens';
import { isMobileOrTablet } from 'utils';
import { ComputerScreen } from 'assets/svg';
import ZohoSalesIQ from 'components/common/ChatBot/ZohoSalesIQ';

const TabPageWrapper: React.FC = () => {
  const isMobOrTab = isMobileOrTablet();
  return (
    <div className='flex flex-col max-h-screen min-h-screen'>
      {/* Navigation */}
      <DashboardNav type={USER_TYPE?.STUDENT} />

      {/* Main Content Area */}
      {isMobOrTab ? (
        <div className='flex items-center justify-center h-[80vh]'>
          <span className='flex items-center'>
            Please Use Laptop or Desktop <img src={ComputerScreen} alt='computerScreenSvg' />
          </span>
        </div>
      ) : (
        <div className='flex-1 py-1 mx-4 lg:mx-8'>
          {/* Tabs Component */}
          {/* <TabsComp tabs={[{ title: 'Class View', content: <ClassNotesView /> }]} /> */}
          <ClassNotesView />
        </div>
      )}

      {/* Footer */}
      <div className='w-full bg-white border-t'>
        <Footer />
        {CONFIG.VITE_BASE_DOMAIN_ENV !== 'production' && <ZohoSalesIQ />}
      </div>
    </div>
  );
};

export default TabPageWrapper;
