import { memo } from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { CountdownTimerProps } from 'types/inClass/PreClassScreen';
import { TimeUnit, TimeSeparator } from 'components/inClass';

const CountdownTimer = memo(({ date }: CountdownTimerProps) => {
  moment.tz.setDefault('Asia/Kolkata');
  return (
    <Countdown
      date={date}
      renderer={({ hours, minutes, seconds }) => (
        <div className='flex items-center justify-center space-x-4 md:space-x-10'>
          <TimeUnit value={hours} label='hrs' />
          <TimeSeparator />
          <TimeUnit value={minutes} label='min' />
          <TimeSeparator />
          <TimeUnit value={seconds} label='sec' />
        </div>
      )}
    />
  );
});

CountdownTimer.displayName = 'CountdownTimer';

export default CountdownTimer;
