import moment from 'moment';
import { useDyteSelector } from '@dytesdk/react-web-core';
import { motion } from 'framer-motion';
import Cookie from 'js-cookie';
import { isArray } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate, useParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

import { InClassUprioLogo } from 'assets/svg';
import { Modal } from 'components/common';
import { ClassStarted, Topic } from 'components/inClass/Badges';
import InClassModal from 'components/inClass/Modal';
import { ParentOnboarding } from 'components/screens';
import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { useGetStudentParentDetailsForInClassQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { CONFIG, CONTAINER_VARIANTS, STUDENT_PATHS, USER_TYPE } from 'configs';
import { useMeeting, useWindowDimensions } from 'hooks';
import { useAddJoiningExperinceMutation } from 'store/apiSlices/inClass/inClassCommon.apiSlice';
import { getBrowserInfo, testAudioDevice, testVideoDevice } from 'utils';
import { getBrightness } from 'utils/getBrightness';
import { useDeviceType } from 'utils/inClass/checkDeviceType';
import DeviceErrorState from './DeviceErrorState';
import { ScreenOrientation, VideoPreview } from './Modals';
import SetupScreenControls from './SetupScreenControls';
import { useSetupScreenMediaDevices } from 'hooks/inClass/useSetupScreenMediaDevices';

const StudentSetupScreen = () => {
  const { id } = useParams();
  const { isMobileScreen } = useWindowDimensions();
  const { meeting, loginTempToken, meetingStartTime, audioStatus, videoStatus } = useMeeting();
  const navigate = useNavigate();
  const { deviceType, orientation } = useDeviceType();
  const userName = useDyteSelector((meeting) => meeting?.self?.name) ?? 'You';
  const videoEnabled = useDyteSelector((m) => m.self.videoEnabled);
  moment.tz.setDefault('Asia/Kolkata');

  const { videoPermissionDenied, audioPermissionDenied, cameraInUse, checkPermissions } =
    useSetupScreenMediaDevices();

  // State management with useRef for values that don't need re-renders
  const deviceTestTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isTestingRef = useRef(false);

  // States that affect rendering
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoVisible, setVideoVisible] = useState<boolean>(true);
  const [isMobileModal, setIsMobileModal] = useState<boolean>(false);
  const [isParentOnboarding, setIsParentOnboarding] = useState<boolean>(false);
  const [isStudentInTime, setIsStudentInTime] = useState<boolean>(true);
  const [testDevices, setTestDevices] = useState<boolean>(false);
  const [cameraWorking, setCameraWorking] = useState<boolean>(false);
  const [micWorking, setMicWorking] = useState<boolean>(false);
  const [mediaTestRun, setMediaTestRun] = useState<boolean>(false);
  const [permissionsGranted, setPermissionsGranted] = useState<boolean | null>(true);
  const [previousCameraStatus, setPreviousCameraStatus] = useState<boolean | null>(null);

  // API states with memoized values
  const [triggerAddJoinExperience] = useAddJoiningExperinceMutation();

  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery(
    { authToken: String(id) },
    { skip: !id },
  );

  const { data: studentParentDetails } = useGetStudentParentDetailsForInClassQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  // Memoized values
  const lectureName = useMemo(() => {
    const { lectureConfigurationName, classConfigurationName, name } =
      JoinClassRoomData?.data || {};
    return lectureConfigurationName || classConfigurationName || name || '';
  }, [JoinClassRoomData?.data]);

  const parentData = useMemo(() => {
    return (
      studentParentDetails &&
      isArray(studentParentDetails?.data) &&
      studentParentDetails?.data.some((data) => data?.members?.userType === USER_TYPE.GUARDIAN)
    );
  }, [studentParentDetails]);

  // Token management
  useEffect(() => {
    if (!Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`)) {
      Cookie.set(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`, loginTempToken || '');
    }
  }, [loginTempToken]);

  // Device testing functions
  const testCam = useCallback(async () => {
    if (isTestingRef.current) return;
    isTestingRef.current = true;

    try {
      const result = await testVideoDevice({
        videoTrack: meeting.self.videoTrack,
        studentId: Number(JoinClassRoomData?.data?.studentId),
        classId: Number(JoinClassRoomData?.data?.classId),
        setCameraWorking,
        setCameraLogTriggered: () => {},
        cameraLogTriggered: false,
        triggerAddJoinExperience,
        previousCameraStatus,
        isVideoEnabled: meeting.self.videoEnabled,
      });
      setPreviousCameraStatus(result);
      return result;
    } catch (error) {
      datadogLogs.logger.error(
        `Camera test failed of ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
        {
          error,
          classId: JoinClassRoomData?.data?.classId,
          studentId: JoinClassRoomData?.data?.studentId,
        },
      );
      setCameraWorking(false);
      return false;
    } finally {
      isTestingRef.current = false;
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    meeting.self.videoTrack,
    previousCameraStatus,
    triggerAddJoinExperience,
    meeting.self.videoEnabled,
  ]);

  const testMic = useCallback(async () => {
    if (isTestingRef.current) return;
    isTestingRef.current = true;

    try {
      const browserInfo = getBrowserInfo();
      return await testAudioDevice({
        track: meeting.self.audioTrack,
        config: {
          studentId: Number(JoinClassRoomData?.data?.studentId),
          classId: Number(JoinClassRoomData?.data?.classId),
          browserInfo: browserInfo?.name,
        },
        callbacks: {
          onDeviceStatusChange: (working) => setMicWorking(working),
          onTestComplete: async (success) => {
            if (!success && meeting.self.audioEnabled) {
              await triggerAddJoinExperience({
                classId: JoinClassRoomData?.data?.classId.toString() || '',
                JoiningExperinceBody: {
                  facedIssue: true,
                  deviceName: 'mic',
                },
              });
            }
          },
        },
        isAudioEnabled: meeting.self.audioEnabled,
      });
    } catch (error) {
      datadogLogs.logger.error(`Microphone test failed`, {
        error,
        classId: JoinClassRoomData?.data?.classId,
        studentId: JoinClassRoomData?.data?.studentId,
      });
      setMicWorking(false);
      return false;
    } finally {
      isTestingRef.current = false;
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    meeting.self.audioTrack,
    triggerAddJoinExperience,
    meeting.self.audioEnabled,
  ]);

  // Parent onboarding modal
  useEffect(() => {
    if (studentParentDetails && !parentData) {
      setIsModalOpen(true);
    }
  }, [parentData, studentParentDetails]);

  useEffect(() => {
    if (isParentOnboarding) {
      setIsModalOpen(false);
    }
  }, [isParentOnboarding]);

  // Media test management
  const mediaTestPassed = useMemo(() => cameraWorking && micWorking, [cameraWorking, micWorking]);
  const showMediaButton = mediaTestRun && (!mediaTestPassed || !mediaTestRun);

  const runMediaTest = useCallback(
    async ({ mic, cam }: { mic: boolean; cam: boolean }) => {
      if (deviceTestTimeoutRef.current) {
        clearTimeout(deviceTestTimeoutRef.current);
      }

      const testResults = { camera: false, microphone: false };

      try {
        if (mic) {
          testResults.microphone = (await testMic()) ? true : false;
        }
        if (cam) {
          testResults.camera = (await testCam()) || false;
        }

        setMediaTestRun(true);
        return testResults.camera && testResults.microphone;
      } catch (error) {
        datadogLogs.logger.error('Media test failed', { error });
        return false;
      }
    },
    [testCam, testMic],
  );

  useEffect(() => {
    if (audioPermissionDenied || videoPermissionDenied) {
      checkPermissions();
    }
  }, [audioPermissionDenied, checkPermissions, videoPermissionDenied]);

  // Device testing effects
  useEffect(() => {
    checkPermissions();
    if (videoStatus && permissionsGranted) {
      testCam();
    } else {
      setCameraWorking(false);
    }
  }, [videoStatus, permissionsGranted, testCam, checkPermissions]);

  useEffect(() => {
    checkPermissions();
    if (audioStatus && permissionsGranted) {
      testMic();
    } else {
      setMicWorking(false);
    }
  }, [audioStatus, checkPermissions, permissionsGranted, testMic]);

  // Join meeting
  const joinMeeting = useCallback(
    async ({ isMediaTest }: { isMediaTest: boolean }) => {
      try {
        if (!isMediaTest) {
          await meeting?.joinRoom();
          return;
        }
        const testPassed = await runMediaTest({ mic: true, cam: true });
        if (testPassed) {
          await meeting?.joinRoom();
        } else {
          setTestDevices(true);
        }
      } catch (error) {
        datadogLogs.logger.error('Error joining meeting', { error });
        window.location.reload();
      }
    },
    [meeting, runMediaTest],
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (showMediaButton || testDevices) {
          if (!mediaTestPassed) {
            return;
          }
          joinMeeting({ isMediaTest: false });
        } else {
          joinMeeting({ isMediaTest: true });
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [joinMeeting, mediaTestPassed, showMediaButton, testDevices]);

  // Leave meeting, disabling the media devices before leaving
  const handleClose = useCallback(() => {
    try {
      if (meeting?.self?.videoEnabled) {
        meeting.self.disableVideo();
      }
      if (meeting?.self?.audioEnabled) {
        meeting.self.disableAudio();
      }
      navigate(STUDENT_PATHS.STUDENT_DASHBOARD);
    } catch (error) {
      datadogLogs.logger.error('Error leaving meeting', { error });
    }
  }, [meeting.self, navigate]);

  // Device change handlers
  const handleCameraChange = useCallback(
    async (deviceId: string) => {
      try {
        const currentDevices = await meeting?.self?.getCurrentDevices();
        if (currentDevices?.video?.deviceId !== deviceId) {
          await meeting?.self?.disableVideo();
          await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: deviceId } },
          });
          await meeting?.self?.enableVideo();
          await runMediaTest({ mic: false, cam: true });
        }
      } catch (error) {
        datadogLogs.logger.error('Error changing camera', { error });
      }
    },
    [meeting?.self, runMediaTest],
  );

  const handleMicrophoneChange = useCallback(
    async (deviceId: string) => {
      try {
        const currentDevices = await meeting?.self?.getCurrentDevices();
        if (currentDevices?.audio?.deviceId !== deviceId) {
          await meeting?.self?.disableAudio();
          await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: { exact: deviceId } },
          });
          await meeting?.self?.enableAudio();
          await runMediaTest({ mic: true, cam: false });
        }
      } catch (error) {
        datadogLogs.logger.error('Error changing microphone', { error });
      }
    },
    [meeting?.self, runMediaTest],
  );

  // Video brightness monitoring
  useEffect(() => {
    if (!videoEnabled) return;

    const { videoTrack } = meeting.self;
    if (!videoTrack) return;

    const videoStream = new MediaStream();
    videoStream.addTrack(videoTrack);
    const video = document.createElement('video');
    video.style.width = '240px';
    video.style.height = '180px';
    video.muted = true;
    video.srcObject = videoStream;

    const handleCanPlay = () => {
      video.play();
    };

    video.addEventListener('canplay', handleCanPlay);

    const canvas = document.createElement('canvas');
    canvas.width = 240;
    canvas.height = 180;
    const ctx = canvas.getContext('2d', { willReadFrequently: true })!;

    const interval = setInterval(() => {
      const brightness = getBrightness(video, canvas, ctx);
      setVideoVisible(brightness >= 0.2);
    }, 1000);

    return () => {
      clearInterval(interval);
      video.removeEventListener('canplay', handleCanPlay);
      video.srcObject = null;
    };
  }, [meeting?.self, videoEnabled]);

  // Mobile device handling
  useEffect(() => {
    setIsMobileModal(isMobileScreen);
  }, [isMobileScreen]);

  // Cleanup
  useEffect(() => {
    checkPermissions();
    return () => {
      if (deviceTestTimeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(deviceTestTimeoutRef.current);
      }
    };
  }, [checkPermissions]);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={CONTAINER_VARIANTS}
      className='flex items-center justify-center min-h-screen transition duration-100 ease-in translate-x-0'
    >
      <div className='relative w-full max-w-5xl pt-4 pb-8 mx-4 shadow-lg bg-primary-850 rounded-3xl'>
        {/* Header */}
        {JoinClassRoomData && (
          <div className='flex items-center justify-between mx-6'>
            <div className='flex items-center justify-between'>
              <img src={InClassUprioLogo} alt='logo' />
              <div className='h-10 ml-4 mr-2 border-l-2 border-white opacity-20' />
              <Topic subject={JoinClassRoomData?.data?.subject} topic={lectureName} />
            </div>
            <ClassStarted startTime={new Date(JoinClassRoomData?.data?.meetingStartTime)} />
          </div>
        )}
        {/* Body */}
        <div className='flex flex-col items-center w-full my-6'>
          <div className='flex flex-col items-center py-6'>
            <p className='text-base text-primary-400 2xl:text-lg'>
              Please ensure that your video and audio remain active at all times.
            </p>
          </div>
          {/* Video and Device Error Container with consistent width */}
          <div className='flex flex-wrap items-start justify-center w-full gap-6 px-6 h-96'>
            {/* Video Preview - Fixed width container */}
            <VideoPreview
              videoPermissionDenied={videoPermissionDenied}
              audioPermissionDenied={audioPermissionDenied}
              cameraInUse={cameraInUse}
              permissionsGranted={permissionsGranted}
              setPermissionsGranted={setPermissionsGranted}
              videoVisible={videoVisible}
              videoEnabled={videoEnabled}
              userName={userName}
              joinMeeting={joinMeeting}
            />

            {/* Device Error State - Fixed width container */}
            <div className='w-[360px] lg:w-[400px] h-96 flex-shrink-0'>
              {/* Device Controls */}
              <DeviceErrorState
                onCameraChange={handleCameraChange}
                onMicrophoneChange={handleMicrophoneChange}
                className='w-full'
              />
            </div>
          </div>
        </div>
        {/* Controls */}
        <SetupScreenControls
          handleClose={handleClose}
          showMediaButton={showMediaButton}
          testDevices={testDevices}
          isStudentInTime={isStudentInTime}
          mediaTestPassed={mediaTestPassed}
          joinMeeting={joinMeeting}
        />
        {/* Countdown Timer */}
        {isStudentInTime && (
          <div className='absolute top-0 left-0 z-30 flex flex-col items-center justify-center text-white rounded-3xl backdrop-blur-md size-full'>
            You will be able to access the class in
            <Countdown
              date={new Date(meetingStartTime || '').getTime() - 60000}
              precision={0}
              renderer={({ minutes, seconds, completed }) => {
                if (completed) {
                  setIsStudentInTime(false);
                  return null;
                } else {
                  setIsStudentInTime(true);
                  return (
                    <span>
                      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </span>
                  );
                }
              }}
            />
          </div>
        )}
        {/* MODAL FOR CHECKING PARENT HAS ONBOARDED */}
        <Modal
          heading='Parent Onboarding'
          children={
            <div>
              <ParentOnboarding isParentOnboarding={setIsParentOnboarding} />
            </div>
          }
          showCloseBtn={false}
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
        />
        {/*
         * MODAL FOR SHOWING DETAILS BASED ON THE JOIN THROUGH
         * IF WE JOIN THROUGH MOBILE ASKING STUDENT TO JOIN USING LAPTOP OR TABLET
         * IF WE JOIN THROUGH TABLET AND SCREEN ORIENTATION IS PORTRAIT MEANS ASKING THE STUDENT TO USE LANDSCAPE
         */}
        <InClassModal
          showCloseBtn={false}
          size={'md'}
          openModal={isMobileModal}
          setOpenModal={setIsMobileModal}
        >
          <ScreenOrientation deviceType={deviceType} orientation={orientation} />
        </InClassModal>
      </div>
    </motion.div>
  );
};

export default StudentSetupScreen;
